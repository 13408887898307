import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [~6],
		"/about": [7],
		"/about/licensing/publishers": [8],
		"/about/licensing/storytellers": [9],
		"/about/privacy-policy": [10],
		"/about/terms-of-service": [11],
		"/account": [12,[2]],
		"/account/billing": [~13,[2]],
		"/account/downloads": [~14,[2]],
		"/account/earnings": [15,[2]],
		"/account/general": [~16,[2]],
		"/account/invoice/[id]": [~17,[2]],
		"/account/licensing-history": [~18,[2]],
		"/account/notifications": [~19,[2]],
		"/account/orders": [~20,[2]],
		"/account/subscription": [21,[2]],
		"/admin": [22,[3]],
		"/admin/backups": [~23,[3]],
		"/admin/migrate": [~24,[3]],
		"/admin/themes": [~25,[3]],
		"/admin/types": [~26,[3]],
		"/admin/users": [~27,[3]],
		"/admin/users/[id]": [~28,[3]],
		"/author/[username]": [~29],
		"/bookmarks": [~30],
		"/contact": [31],
		"/download/[slug]": [~32],
		"/editor-board": [~33],
		"/how-it-works": [34],
		"/how-it-works/publisher": [35],
		"/how-it-works/storyteller": [36],
		"/members": [~37],
		"/order/[id]/download": [~38,[],[4]],
		"/order/[id]/organizational-details": [~39,[],[4]],
		"/order/[id]/plan-active": [40,[],[4]],
		"/order/[id]/pricing": [~41,[],[4]],
		"/order/[id]/publication-details": [~42,[],[4]],
		"/our-story": [43],
		"/people": [~44],
		"/plain-html-story/[slug]": [~45],
		"/pricing": [46],
		"/profile": [~47],
		"/profile/edit": [~49],
		"/profile/[username]": [~48],
		"/search": [50,[5]],
		"/search/[query]": [51,[5]],
		"/sign-in": [52],
		"/sign-up": [53],
		"/sign-up/error": [54],
		"/sign-up/setup-account": [~55],
		"/sign-up/verify-email": [56],
		"/stories": [~57],
		"/story-backup/[slug]": [~61],
		"/story-manager": [~62],
		"/story/create": [~60],
		"/story/[slug]": [~59],
		"/story/[id]/edit": [~58]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';